import React from "react"
import { Link } from "gatsby"
import UseHttpRequest from "../../hooks/HttpRequestEvents"
import apiRequest from "../../hooks/HttpRequest"
import Moment from "moment"
const RecentEvents = () => {
  const allData = UseHttpRequest()
  if (allData.length <= 0) {
    return (
      <div className="row justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  const data = allData.Events

  // console.log(data);
  return (
    <>
      {
        data.map((eventItem, index) => (
          <article key={index} className="item">
            <Link to={"/event/" + eventItem.Slug} className="thumb">
              {eventItem.ImageURL.length > 0 ? (
                <img
                  className="fullimage cover bg4"
                  src={eventItem.ImageURL}
                  alt="no events"
                />
              ) : (
                <img
                  className="fullimage cover bg4"
                  src={apiRequest.baseURL + "images/no-image.png"}
                  alt="No Event"
                />
              )}
            </Link>
            <div className="info">
              <time dateTime="2019-06-30">
                {Moment(eventItem.EDate).format("MM-DD-YYYY") + " " + eventItem.ETime}
              </time>
              <h4 className="title usmall">
                <Link to={"/event/" + eventItem.Slug.toString() + "/"}>
                  {eventItem.EName}
                  <br /> {eventItem.Description.substring(0, 60) + "..."}
                </Link>
              </h4>
            </div>
            <div className="clear" />
          </article>
        ))}
    </>
  )
}

export default RecentEvents

import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
// import EventDetailPage from "../components/events/eventDetail";
import SEO from "../components/seo"
import EventDetail from "../components/events/eventDetail"
export default function eventDetail() {
  return (
    <Layout>
      <SEO
        title="Events | Kentucky Tamil Sangam"
        desc="Events | Kentucky Tamil Sangam"
        keywords="Events Kentucky Tamil Sangam"
      />
      <Router>
        <EventDetail path="/event/:slug" />
      </Router>
    </Layout>
  );
};

import React, { Component } from "react"
import axios from "axios"
//import { Link } from "gatsby"
import { isLoggedIn, getUser } from "../../services/auth"
import { Form } from "reactstrap"
import apiRequest from "../../hooks/HttpRequest"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Paypal from "gatsby-plugin-paypal"

export default class EventTicketing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            membersArray: [],
            MemberID: isLoggedIn() ? getUser().KyTSUser.MemberID : 0,
            FName: "",
            LName: "",
            MobileNo: "",
            PrimaryEmail: "",

            showNextButton: true,
            showTicketItems: isLoggedIn() ? true : false,

            foodItemsArray: [],
            selectedFoodItemsArray: [],
            isMemberPurchasing: isLoggedIn() ? true : false,

            EventID: this.props.EventID,
            isPurchaseCompleted: false,
            TotalPurchase: 0

        }
    }

    componentDidMount() {
        // console.log("Passed In EventID", this.props.EventID);
        this.getFoodItems()
    }

    paymentHandler = (details, data) => {
        /** Here you can call your backend API
          endpoint and update the database */
        // console.log(details, data);
    }

    getFoodItems() {
        axios
            .get(apiRequest.baseURL + "/EventFoodItems/GetAllEventFoodItems/" + this.state.EventID, apiRequest.jsonHeader)
            .then((response) => {
                this.setState({
                    foodItemsArray: response.data.EventFoodItems,
                }, () => {
                    // console.log(this.state.foodItemsArray);
                    var localSelectedFoodItemsArray = [];
                    if (this.state.isMemberPurchasing) {
                        this.state.foodItemsArray.forEach(element => {
                            localSelectedFoodItemsArray.push({ "FoodItemID": element.FoodItemID, "FoodItemName": element.ItemName, "Quantity": 0, "ItemPrice": element.ItemMemberPrice, "ItemTotal": 0 });
                        });
                    }
                    else {
                        this.state.foodItemsArray.forEach(element => {
                            localSelectedFoodItemsArray.push({ "FoodItemID": element.FoodItemID, "FoodItemName": element.ItemName, "Quantity": 0, "ItemPrice": element.ItemGuestPrice, "ItemTotal": 0 });
                        });
                    }
                    this.setState({
                        selectedFoodItemsArray: localSelectedFoodItemsArray,
                    }, () => {
                        // console.log("selectedFoodItemsArray", this.state.selectedFoodItemsArray)
                    })
                })
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    handleFoodItemChange = (e) => {
        // console.log(e.target.id);

        //// console.log("before update selectedFoodItemsArray", this.state.selectedFoodItemsArray);
        const index = this.state.selectedFoodItemsArray.findIndex((item) => parseInt(item.FoodItemID) === parseInt(e.target.id));
        //// console.log("index", index);
        var localSelectedFoodItems = [...this.state.selectedFoodItemsArray];
        localSelectedFoodItems[index] = { "FoodItemID": parseInt(e.target.id), "FoodItemName": this.state.selectedFoodItemsArray[index].FoodItemName, "Quantity": parseInt(e.target.value), "ItemPrice": this.state.selectedFoodItemsArray[index].ItemPrice, "ItemTotal": this.state.selectedFoodItemsArray[index].ItemPrice * parseInt("0" + e.target.value) };
        this.setState({
            selectedFoodItemsArray: localSelectedFoodItems
        }, () => {
            // console.log("updated selectedFoodItemsArray", this.state.selectedFoodItemsArray);
            //// console.log(this.state.selectedFoodItemsArray[index].Quantity);
            var localTotalPurchase = 0;
            localSelectedFoodItems.forEach(item => {
                localTotalPurchase += parseFloat(item.ItemTotal);
            });
            this.setState({
                TotalPurchase: localTotalPurchase,
            });
        });
    }

    checkMembership = (e) => {
        //e.preventDefault();
        const formData = new FormData()
        formData.append("FName", this.state.FName)
        formData.append("LName", this.state.LName)
        formData.append("PrimaryPhone", this.state.PrimaryPhone)
        formData.append("PrimaryEmail", this.state.PrimaryEmail)
        formData.append("MemberID", parseInt("0" + this.state.MemberID))


        // Display the key/value pairs
        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1]);
        }
        //    return;
        return axios
            .post(
                apiRequest.baseURL + "/Members/CheckMembership",
                formData,
                apiRequest.multipartFormHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {

                    this.setState({
                        showNextButton: false,
                        showTicketItems: true,
                        isMemberPurchasing: true
                    });

                } else {
                    this.setState({
                        showNextButton: false,
                        showTicketItems: true,
                        isMemberPurchasing: false
                    });
                }
            })
            .catch((e) => {
                // console.log(e)
            })
    }

    //checkoutTicketing = (e) => {
    checkoutTicketing() {
        console.log("inside checkoutTicketing");
        //e.preventDefault();

        // console.log(getUser().KyTSUser && getUser().KyTSUser);
        if (getUser().KyTSUser && getUser().KyTSUser.MemberID > 0) {
            this.setState({
                FName: getUser().KyTSUser.FName,
                LName: getUser().KyTSUser.LName,
                PrimaryEmail: getUser().KyTSUser.PrimaryEmail,
                PrimaryPhone: getUser().KyTSUser.PrimaryPhone,
                MemberID: getUser().KyTSUser.MemberID
            });
        }

        // console.log("ValidItems", this.state.selectedFoodItemsArray.filter((item) => parseInt(item.Quantity) > 0));
        const jsonPayload = {
            EventID: this.state.EventID,
            FirstName: this.state.FName,
            LastName: this.state.LName,
            Email: this.state.PrimaryEmail,
            Phone: this.state.PrimaryPhone,
            MemberID: this.state.MemberID,
            PurchaseItems: this.state.selectedFoodItemsArray.filter((item) => parseInt(item.Quantity) > 0),
            Total: this.state.TotalPurchase
        }

        // console.log("jsonPayload", jsonPayload);
        return axios
            .post(
                apiRequest.baseURL + "/Purchases/InsertPurchase",
                jsonPayload,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        isPurchaseCompleted: true
                    });
                    toast.success("Ticket purchase completed!!")
                } else {
                    this.setState({
                        showNextButton: false,
                        showTicketItems: false,
                        isMemberPurchasing: false
                    });
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }
    //handles form inputs change events
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onApprove(data, actions) {
        console.log("onApprove", data, actions);
        if (1 == 2) {
            this.checkoutTicketing()
        }
        return actions.order.capture();
    }

    onCancel(data) {
        console.log("onCancel", data);
        if (data !== null) {
            toast.error("Payment Cancelled. Unable to checkout!!");
        }
    }

    onError(error) {
        console.log("onError", error);
        if (error !== null) {
            toast.error("Payment Error. Unable to checkout!!");
        }
    }

    createOrder(data, actions) {
        var totalCheckoutAmount = this.state.TotalPurchase;
        console.log("totalCheckoutAmount", totalCheckoutAmount);
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: totalCheckoutAmount
                    },
                },
            ],
        });
    };


    render() {
        return (
            <>
                <div className={!this.state.isPurchaseCompleted ? "signup-form mw-100" : "signup-form mw-100 d-none"}>
                    <h3>Buy Event Tickets</h3>

                    <ToastContainer />

                    <div className="row">
                        {!isLoggedIn() ?
                            <>
                                <Form>
                                    <p>Please furnish below details</p>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                name="FName"
                                                required
                                                type="text"
                                                className="form-control"
                                                placeholder="First Name"
                                                onChange={this.handleChange}
                                                value={this.state.FName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="LName"
                                                className="form-control"
                                                placeholder="Last Name"
                                                value={this.state.LName}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                placeholder="Email Address"
                                                required
                                                type="email"
                                                name="PrimaryEmail"
                                                className="form-control"
                                                value={this.state.PrimaryEmail}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                placeholder="Phone Number"
                                                maxLength="10"
                                                required
                                                type="tel"
                                                pattern="[0-9]{10}"
                                                name="PrimaryPhone"
                                                className="form-control"
                                                value={this.state.PrimaryPhone}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                placeholder="KYTS Membership ID"
                                                type="tel"
                                                name="MemberID"
                                                className="form-control"
                                                value={this.state.MemberID}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="send-btn">
                                            <button type="submit" onClick={this.checkMembership} className={this.state.showNextButton ? "default-btn" : "default-btn d-none"}>
                                                Next
                                        </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                            : ""}
                        <Form onSubmit={this.checkoutTicketing}>
                            <div className={this.state.showTicketItems ? "row" : "d-none"}>

                                <div className="col-lg-12 mt-4 ">
                                    <h4>Choose No of Tickets and Items</h4>
                                </div>
                                <div className="col-lg-12 ">
                                    <table className="table table-bordered mt-4" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Item Name</th>
                                                <th className="text-center">{this.state.isMemberPurchasing ? "Member" : "Guest"} Price</th>
                                                <th className="text-center">Quantity</th>
                                                <th style={{ textAlign: "right" }}>Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.foodItemsArray && this.state.foodItemsArray.map((item, index) => {
                                                    return (

                                                        <tr key={index}>
                                                            <td>{item.ItemName}</td>
                                                            <td className="text-center">$ {this.state.isMemberPurchasing ? item.ItemMemberPrice : item.ItemGuestPrice}</td>
                                                            <td className="text-center">
                                                                <select id={item.FoodItemID} name={"select" + item.FoodItemID} onChange={this.handleFoodItemChange} required={item.ItemName.indexOf("Entry Fee") > -1}>
                                                                    <option value=""></option>
                                                                    <option>1</option>
                                                                    <option>2</option>
                                                                    <option>3</option>
                                                                    <option>4</option>
                                                                    <option>5</option>
                                                                    <option>6</option>
                                                                    <option>7</option>
                                                                    <option>8</option>
                                                                    <option>9</option>
                                                                    <option>10</option>
                                                                </select></td>
                                                            <td align="right">$ {this.state.selectedFoodItemsArray[index] && (this.state.selectedFoodItemsArray[index]).ItemTotal.toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3">Total</td>
                                                <td align="right"><strong>$ {this.state.TotalPurchase.toFixed(2)}</strong></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="col-lg-12">
                                    <div className="send-btn text-center">
                                        <button type="submit" className="default-btn  d-none">
                                            Checkout
                                        </button>
                                        Checkout with
                                        <Paypal
                                            style={{
                                                shape: 'rect',
                                                color: 'blue',
                                                layout: 'horizontal',
                                                label: 'paypal',
                                            }}
                                            createOrder={(data, actions) => this.createOrder(data, actions)}
                                            currency="USD" onError={error => this.onError(error)} onCancel={data => this.onCancel(data)} onApprove={(data, actions) => this.onApprove(data, actions)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <div className={this.state.isPurchaseCompleted ? "signup-form mw-100" : "signup-form mw-100 d-none"}>
                    <h4>Thank you for the Ticket Purchase!</h4>
                </div>
            </>
        )
    }
}

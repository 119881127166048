import React, { Component } from "react"
import axios from "axios"
//import { Link } from "gatsby"
import { isLoggedIn, getUser } from "../../services/auth"
import { Form } from "reactstrap"
import apiRequest from "../../hooks/HttpRequest"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Paypal from "gatsby-plugin-paypal"

export default class EventRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            participantsArray: [],
            MemberID: isLoggedIn() ? getUser().KyTSUser.MemberID : 0,
            TeamName: "",
            SponsorMemberID: "",
            SponsorFName: "",
            SponsorLName: "",
            SponsorEmail: "",
            SponsorContactNo: "",
            ParticipantMemberID: "",
            ParticipantFName: "",
            ParticipantLName: "",
            ParticipantEmail: "",
            ParticipantContactNo: "",
            ParticipantAgeGroup: "",
            ChoereographerMemberID: "",
            ChoereographerFName: "",
            ChoereographerLName: "",
            ChoereographerEmail: "",
            ChoereographerContactNo: "",
            IsProgramTypeSinging: true,
            SongMovieName: "",
            SongYouTubeLink: "",

            showRegistrationForm: false,
            isRegistrationCompleted: false,
            EventID: this.props.EventID,

        }
    }

    componentDidMount() {
        // console.log("Passed In EventID", this.props.EventID);
    }

    paymentHandler = (details, data) => {
        /** Here you can call your backend API
          endpoint and update the database */
        // console.log(details, data);
    }



    checkMembership = (e) => {
        //e.preventDefault();
        const formData = new FormData()
        formData.append("FName", this.state.FName)
        formData.append("LName", this.state.LName)
        formData.append("PrimaryPhone", this.state.PrimaryPhone)
        formData.append("PrimaryEmail", this.state.PrimaryEmail)
        formData.append("MemberID", parseInt("0" + this.state.MemberID))


        // Display the key/value pairs
        for (var pair of formData.entries()) {
            // console.log(pair[0] + ', ' + pair[1]);
        }
        //    return;
        return axios
            .post(
                apiRequest.baseURL + "/Members/CheckMembership",
                formData,
                apiRequest.multipartFormHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {

                    this.setState({
                        showNextButton: false,
                        showTicketItems: true,
                        isMemberPurchasing: true
                    });

                } else {
                    this.setState({
                        showNextButton: false,
                        showTicketItems: true,
                        isMemberPurchasing: false
                    });
                }
            })
            .catch((e) => {
                // console.log(e)
            })
    }

    //checkoutTicketing = (e) => {
    checkoutTicketing() {
        console.log("inside checkoutTicketing");
        //e.preventDefault();

        // console.log(getUser().KyTSUser && getUser().KyTSUser);
        if (getUser().KyTSUser && getUser().KyTSUser.MemberID > 0) {
            this.setState({
                FName: getUser().KyTSUser.FName,
                LName: getUser().KyTSUser.LName,
                PrimaryEmail: getUser().KyTSUser.PrimaryEmail,
                PrimaryPhone: getUser().KyTSUser.PrimaryPhone,
                MemberID: getUser().KyTSUser.MemberID
            });
        }

        // console.log("ValidItems", this.state.selectedFoodItemsArray.filter((item) => parseInt(item.Quantity) > 0));
        const jsonPayload = {
            EventID: this.state.EventID,
            FirstName: this.state.FName,
            LastName: this.state.LName,
            Email: this.state.PrimaryEmail,
            Phone: this.state.PrimaryPhone,
            MemberID: this.state.MemberID,
            PurchaseItems: this.state.selectedFoodItemsArray.filter((item) => parseInt(item.Quantity) > 0),
            Total: this.state.TotalPurchase
        }

        // console.log("jsonPayload", jsonPayload);
        return axios
            .post(
                apiRequest.baseURL + "/Purchases/InsertPurchase",
                jsonPayload,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        isPurchaseCompleted: true
                    });
                    toast.success("Ticket purchase completed!!")
                } else {
                    this.setState({
                        showNextButton: false,
                        showTicketItems: false,
                        isMemberPurchasing: false
                    });
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }
    //handles form inputs change events
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }



    render() {
        return (
            <>
                <div className={this.state.showRegistrationForm ? "signup-form mw-100" : "signup-form mw-100 d-none"}>
                    <h3>Buy Event Tickets</h3>

                    <ToastContainer />
                    {!isLoggedIn() ?
                        <Form>
                            <div className="row">
                                <p>Please furnish below details</p>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            name="TeamName"
                                            required
                                            type="text"
                                            className="form-control"
                                            placeholder="Team Name"
                                            onChange={this.handleChange}
                                            value={this.state.TeamName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <p>Sponsor Details</p>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="SponsorMemberID"
                                            className="form-control"
                                            placeholder="Member ID"
                                            value={this.state.SponsorMemberID}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="SponsorFName"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={this.state.SponsorFName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="SponsorLName"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={this.state.SponsorLName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Email Address"
                                            required
                                            type="email"
                                            name="SponsorEmail"
                                            className="form-control"
                                            value={this.state.SponsorEmail}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Phone Number"
                                            maxLength="10"
                                            required
                                            type="tel"
                                            pattern="[0-9]{10}"
                                            name="SponsorContactNo"
                                            className="form-control"
                                            value={this.state.SponsorContactNo}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <p>Choreographer Details</p>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ChoereographerMemberID"
                                            className="form-control"
                                            placeholder="Member ID"
                                            value={this.state.ChoereographerMemberID}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ChoereographerFName"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={this.state.ChoereographerFName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ChoereographerLName"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={this.state.ChoereographerLName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Email Address"
                                            required
                                            type="email"
                                            name="ChoereographerEmail"
                                            className="form-control"
                                            value={this.state.ChoereographerEmail}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Phone Number"
                                            maxLength="10"
                                            required
                                            type="tel"
                                            pattern="[0-9]{10}"
                                            name="ChoereographerContactNo"
                                            className="form-control"
                                            value={this.state.ChoereographerContactNo}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <p>Participants Details</p>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ParticipantMemberID"
                                            className="form-control"
                                            placeholder="Member ID"
                                            value={this.state.ParticipantMemberID}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ParticipantFName"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={this.state.ParticipantFName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="ParticipantLName"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={this.state.ParticipantLName}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Email Address"
                                            required
                                            type="email"
                                            name="ParticipantEmail"
                                            className="form-control"
                                            value={this.state.ParticipantEmail}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input
                                            autoComplete="off"
                                            placeholder="Phone Number"
                                            maxLength="10"
                                            required
                                            type="tel"
                                            pattern="[0-9]{10}"
                                            name="ParticipantContactNo"
                                            className="form-control"
                                            value={this.state.ParticipantContactNo}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select name="ParticipantAgeGroup" value={this.state.ParticipantAgeGroup} onChange={this.handleChange} required>
                                            <option value=""></option>
                                            <option>0-5</option>
                                            <option>5-8</option>
                                            <option>18+</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="send-btn">
                                        <button type="submit" onClick={this.addParticipants} className="default-btn">
                                            Add Participant
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="send-btn">
                                        <button type="submit" onClick={this.saveParticipants} className="default-btn">
                                            Save Participant
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p>&nbsp;</p>
                            <div className="row">
                                <div className="col-lg-12">
                                    <table className="table table-bordered mt-4" width="100%">
                                        <thead>
                                            <tr>
                                                <th>Sl. No</th>
                                                <th className="text-center">Participant Name</th>
                                                <th className="text-center">Contact No</th>
                                                <th className="text-center">Age Group</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                this.state.participantsArray && this.state.participantsArray.map((item, index) => {
                                                    return (

                                                        <tr key={index}>
                                                            <td>{item.ParticipantLName}</td>
                                                            <td>{item.ParticipantEmail}</td>
                                                            <td>{item.ParticipantContactNo}</td>
                                                            <td>{item.ParticipantAgeGroup}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3">Total</td>
                                                <td align="right"><strong>$ {this.state.TotalPurchase.toFixed(2)}</strong></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </Form>
                        : ""}
                </div>
                <div className={this.state.isPurchaseCompleted ? "signup-form mw-100" : "signup-form mw-100 d-none"}>
                    <h4>Thank you for your interest!</h4>
                </div>
            </>
        )
    }
}
